export const enum TICKET_FIELD_NAME {
  RECOMMEND_KEYWORDS = '추천검색어_릴레이션',
  CATEGORY_LEGACY = '문의유형',
  CATEGORY = '문의유형1Depth',
  CATEGORY_SECTION_RELATION = '문의유형2depth_릴레이션',
  FAQ_FEEDBACK_REASON = 'FAQ피드백_도움되지않은 이유',
}

export const enum TICKET_FIELD_ID {
  CATEGORY_LAGACY = 360021974533,
  CATEGORY = 15654866611739,
  CATEGORY_SECTION = 15655039742747,
  MERGE_CHECKED = 22361724016667,
  USER_KEY = 360024925653,
  DEVICE = 360024924913,
  OS = 360024926274,
  APP_VERSION = 360024924673,
  READ_MARKER = 360000106061,
  ADDITIONAL = 26774665245979,
  FAQ_FEEDBACK_SELECT = 27273536635547,
  FAQ_FEEDBACK_COMMENT = 27273549451419,
  FAQ_FEEDBACK_ARTICLE_ID = 27273592797979,
  FAQ_FEEDBACK_ARTICLE_TITLE = 27273591852059,
  FAQ_FEEDBACK_ARTICLE_CATEGORY = 30654139421595,
}

export const enum SECTION_ID {
  INQUIRY_TEMPLATE = 22592036614555,
}
