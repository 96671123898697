import { API } from '@/constants/api'
import { METHOD, request } from '@/utils/api'

import { TicketField } from './types'

export type GetTicketFieldResponse = {
  ticket_field: TicketField;
};

export const getTicketField = (name: string) => {
  return request<GetTicketFieldResponse>({
    url: `${API.ENDPOINT.UW_TMAP}/zendesk/ticket/field/${name}`,
    method: METHOD.GET,
  })
}
