type Restore = () => void

export const bodyScrollLock = (): Restore => {
  const { pageYOffset } = window

  const restoreScroll = pageYOffset
  const restoreStyle = [{
    property: 'overflow',
    value: document.body.style.overflow,
  }, {
    property: 'width',
    value: document.body.style.width,
  }, {
    property: 'position',
    value: document.body.style.position,
  }, {
    property: 'top',
    value: document.body.style.top,
  }]

  const currentTop = parseInt(document.body.style.top, 10)
  const top = currentTop < 0 ? currentTop : pageYOffset * -1

  document.body.style.overflow = 'hidden'
  document.body.style.width = '100%'
  document.body.style.position = 'fixed'
  document.body.style.top = `${top}px`
  window.scrollTo(0, 0)

  const restore = () => {
    restoreStyle.forEach(({ property, value }) => {
      if (value) {
        document.body.style.setProperty(property, value)
      } else {
        document.body.style.removeProperty(property)
      }
    })
    window.scrollTo(0, restoreScroll)
  }

  return restore
}
