const MAIN_ID = '/faqmain'
const getId = (path: string) => `${MAIN_ID}/${path}`

export const LOG_PAGE_ID = {
  MAIN: MAIN_ID,
  SEARCH: getId('search'),
  CATEGORY: getId('category'),
  QNA: {
    INQUIRY: getId('cs'),
    INQUIRY_PREVIEW: getId('cs_pre'),
    INQUIRY_END: getId('cs_complete'),
    LIST: getId('mycs'),
    DETAIL: getId('mycsdetail'),
  },
  FAQ: getId('article'),
  FAQ_FEEDBACK: getId('articlefeedback'),
  ERROR: {
    429: getId('error_api'),
    SERVER: getId('error_server'),
    NETWORK: getId('error_network'),
    ETC: getId('error_temporary'),
  },
}
