import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { NEXT_ID, PORTAL_ID } from '@/constants/common'

interface PortalProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  children: ReactNode;
}

function Portal({ id = PORTAL_ID, children }: PortalProps) {
  const portalRef = useRef<HTMLElement | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    portalRef.current = document.getElementById(id)

    if (!portalRef.current) {
      const nextContainer = document.getElementById(NEXT_ID)
      portalRef.current = document.createElement('div')
      portalRef.current.id = id

      if (nextContainer) {
        nextContainer.appendChild(portalRef.current)
      } else if (document.body) {
        document.body.appendChild(portalRef.current)
      }
    }

    setMounted(true)
  }, [id])

  return mounted && portalRef.current ? createPortal(children, portalRef.current) : null
}

export default Portal
