import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useRouter } from 'next/router'
import { CSSProperties, ReactNode, RefObject, useCallback, useEffect, useState } from 'react'

import { ROUTER } from '@/constants/router'
import { useMount } from '@/hooks/useMount'
import { sendEvent } from '@/libs/mixpanel'
import { COLOR } from '@/styles/color'
import { fontSize, textHidden, touchGuide } from '@/styles/mixin'
import { isClient } from '@/utils/compare'

import IcoArrowLeft from './images/ico_arrow_left.svg?url'

interface HeaderProps {
  pageId?: string;
  title?: string;
  extra?: ReactNode;
  onClickBackButton?: () => void;
  scrollRef?: RefObject<HTMLElement>;
  children?: ReactNode;
  containerStyle?: CSSProperties;
}

function Header({
  pageId,
  title,
  extra,
  onClickBackButton,
  scrollRef,
  children,
  containerStyle,
}: HeaderProps) {
  const router = useRouter()
  const [isScrolling, setIsScrolling] = useState(false)

  const handleClickBackButton = useCallback(() => {
    if (pageId?.length) {
      sendEvent(pageId, 'tap.back')
    }

    if (onClickBackButton) {
      onClickBackButton()
      return
    }

    if (isClient) {
      const { isFirstHistory } = router.query
      const isFirstPath = window.history.length < 2 || !!isFirstHistory
      if (isFirstPath || router.pathname === ROUTER.MAIN) {
        if (TmapApp.env.isInApp) {
          TmapApp.onBackKeyPressed()
        } else {
          window.close()
        }
      } else {
        router.back()
      }
    }
  }, [pageId, router, onClickBackButton])

  // 스크롤 된 여부(scrollTop !== 0) 확인
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef?.current) {
        setIsScrolling(scrollRef.current.scrollTop > 0)
      } else {
        setIsScrolling(window.scrollY > 0)
      }
    }

    if (isClient) {
      if (scrollRef?.current) {
        scrollRef.current.addEventListener('scroll', handleScroll)
      } else {
        document.addEventListener('scroll', handleScroll)
      }
    }

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [scrollRef])

  useMount(() => {
    if (TmapApp.env.isInApp && TmapApp.env.isIOS && isClient) {
      // iOS에서 메인페이지 Sticky 헤더가 사라지는 현상이 있어서 강제로 스크롤 이벤트를 발생
      window.scrollTo({ left: 1 })
    }
  })

  return (
    <Container
      scroll={isScrolling}
      style={containerStyle}
    >
      <ActionArea>
        <BackButton onClick={handleClickBackButton}>뒤로가기</BackButton>
      </ActionArea>
      {!children && !!title?.length && <Title>{title}</Title>}
      {children}
      {extra && <Extra>{extra}</Extra>}
    </Container>
  )
}

const Container = styled.header<{ scroll: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 56px;
  background-color: ${COLOR.gray.color.wb[0]};
  z-index: ${({ theme }) => theme.zIndex.header};

  ${({ scroll }) =>
    scroll &&
    css`
      border-bottom: 1px solid ${COLOR.gray.color.gray[200]};
    `}
`

const ActionArea = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);

  & > button {
    display: block;
  }
`

export const BackButton = styled.button`
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  background: url(${IcoArrowLeft}) no-repeat center;

  ${textHidden()}
  ${touchGuide({
    borderRadius: '100%',
    top: -8,
    bottom: -8,
    left: -8,
    right: -8,
  })}
`

const Title = styled.h1`
  ${({ theme }) => fontSize(theme, 18)}
  font-weight: var(--font-weight-bold);
  color: ${COLOR.gray.color.gray[900]};
`
const Extra = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`

export default Header
