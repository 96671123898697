import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

interface FrameLayoutProps {
  useHeader?: boolean;
  children: ReactNode;
}

function FrameLayout({ useHeader = true, children }: FrameLayoutProps) {
  return <Container useHeader={useHeader}>{children}</Container>
}

const Container = styled.div<{ useHeader: boolean }>`
  ${({ useHeader }) =>
    useHeader &&
    css`
      padding-top: 56px;
    `}
`

export default FrameLayout
