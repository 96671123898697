import styled from '@emotion/styled'
import { ButtonHTMLAttributes } from 'react'

import { textHidden } from '@/styles/mixin'

import IcoSearch from './images/ico_search.svg?url'

interface SearchButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

function SearchButton({ ...rest }: SearchButtonProps) {
  return <Container {...rest}>검색</Container>
}

const Container = styled.button`
  width: 24px;
  height: 24px;
  background: url(${IcoSearch}) no-repeat center center;
  font-size: 0;
  ${textHidden()}
`

export default SearchButton
